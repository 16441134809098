import styled from "styled-components";

const Wrapper = styled.section`
    .news-image {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
`;

export default Wrapper;
